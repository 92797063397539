<div class="container w-100" id="areaBusca">
    <form #formConsulta="ngForm" [formGroup]="formularioConsulta" (ngSubmit)="buscarRequerimento()"
        class="d-flex justify-content-center">
        <div class="card shadow w-50">
            <div class="card-body m-3">
                <div>
                    <p class="h3 fw-bold p-0">Consulta Externa de Requerimento</p>
                </div>
                <div>
                    <p class="p-0">Preencha os dados para visualizar o Requerimento:</p>
                </div>
                <div class="parent">

                    <mat-form-field appearance="outline" class="full-width">

                        <mat-label>Nº Protocolo</mat-label>
                        <input matInput id="numeroProtocolo" name="numeroProtocolo" formControlName="numeroProtocolo"
                            minlength="7" maxlength="8" />

                        <!-- Errors -->
                        <mat-error *ngIf="!formularioConsulta.value.numeroProtocolo">Por favor, informe o número do
                            Protocolo.</mat-error>
                        <mat-error *ngIf="formularioConsulta.controls.numeroProtocolo.hasError('minlength')">O
                            campo Nº Protocolo
                            deverá conter 8 caracteres.</mat-error>

                    </mat-form-field>

                </div>
                <div class="parent">

                    <mat-form-field appearance="outline" class="full-width">

                        <mat-label>Email</mat-label>
                        <input matInput id="emailRequerente" name="emailRequerente" formControlName="emailRequerente"
                            type="email" />

                        <!-- Errors -->
                        <mat-error *ngIf="!formularioConsulta.value.emailRequerente">Por favor, informe o
                            e-mail.</mat-error>
                        <mat-error
                            *ngIf="formularioConsulta.controls.emailRequerente.hasError('email') && !formularioConsulta.controls.emailRequerente.hasError('required')">Email
                            inválido.</mat-error>

                    </mat-form-field>

                </div>
                <div class="parent">

                    <mat-form-field appearance="outline" class="full-width">

                        <mat-label>Token</mat-label>
                        <input matInput id="token" name="token" formControlName="token" [minLength]="36"
                            [maxLength]="36" />

                        <!-- Errors -->
                        <mat-error *ngIf="!formularioConsulta.value.token">Por favor, informe o token.</mat-error>
                        <mat-error *ngIf="formularioConsulta.controls.token.hasError('minlength')">O
                            campo Token deverá
                            conter 36
                            dígitos.</mat-error>

                    </mat-form-field>

                </div>

                <div class="parent">
                    <div *ngIf="mostrarErroNoFormulario" class="alert alert-danger" role="alert">
                        Por favor, preencha todos os campos obrigatórios corretamente.
                    </div>
                </div>
                <div class="text-center p-2">
                    <div class="col-12">
                        <button mat-flat-button class="me-3" color="primary" type="submit"
                            [disabled]="!formularioConsulta.valid">Buscar Requerimento</button>
                    </div>
                </div>

            </div>
        </div>
    </form>
</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#3f51b5" type="ball-atom" name="tokenSpinner">
    <p style="font-size: 20px; color: white">Carregando Requerimento ...</p>
</ngx-spinner>