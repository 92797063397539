<div class="container" id="areaBusca">
    <form #formConsulta="ngForm" [formGroup]="formularioConsulta" (ngSubmit)="consultar()" class="needs-validation"
        novalidate>
        <div class="card shadow">
            <div class="card-body">
                <div class="row g-3">
                    <div class="col-md-4">
                        <label for="matricula" class="form-label">Matricula (apenas números)</label>
                        <input type="text" class="form-control" id="matricula" formControlName="matricula" minlength="3" maxlength="8" (blur)="validateMatricula()">
                        <div class="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div class="col-md-4" >
                        <label for="numeroProtocolo" class="form-label" >N° Protocolo</label>
                        <input type="text" class="form-control" id="numeroProtocolo" formControlName="numeroProtocolo">
                        <div class="valid-feedback">
                            Looks good!
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label for="numeroProtocolo" class="form-label">Status</label>
                        <select id="status" name="status" formControlName="status" class="form-control">
                            <option *ngFor="let option of statusOptions" [value]="option.value">{{ option.label }}</option>
                          </select>
                          
                        <!--  <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="invalidCheck" required>
                    <label class="form-check-label" for="invalidCheck">
                      Agree to terms and conditions
                    </label>
                    <div class="invalid-feedback">
                      You must agree before submitting.
                    </div>
                  </div>
                </div> -->
                    </div>

                    <div class="col-md-4" *ngIf="isAdmin">
                        <label class="form-label" for="numeroSEI">Nome do requerente</label>
                        <input id="nomeRequerente" name="nomeRequerente" type="text" formControlName="nomeRequerente"
                           class="form-control" />
                    </div>

                    <div class="col-md-4" *ngIf="isAdmin">
                        <label class="form-label" for="numeroSEI">Número SEI</label>
                        <input id="numeroSEI" name="numeroSEI" type="text" formControlName="numeroSEI"
                           class="form-control" />
                    </div>

                    <div class="col-md-4" *ngIf="isAdmin">
                        <label class="form-label" for="cpf">CPF</label>
                        <input id="cpf" name="cpf" type="text" formControlName="cpf" style="width: 50%;"
                            class="form-control" />
                    </div>

                    <div class="col-md-4" >
                        <label class="form-label" for="dataInicio">Início</label>
                        <div class="d-flex align-items-center"> <!-- Adicione uma classe flex para alinhar os elementos verticalmente -->
                            <input matInput [matDatepicker]="picker1" [formControlName]="'dataInicio'" id="dataInicio" class="form-control" placeholder="DD/MM/YYYY"/>
                            <mat-datepicker-toggle matIconSuffix [for]="picker1" class="mat-datepicker-start"></mat-datepicker-toggle> <!-- Adicione a classe mat-datepicker-start aqui -->
                            <mat-datepicker #picker1></mat-datepicker>
                        </div>
                    </div>
                    
                    <div class="col-md-4" >
                        <label class="form-label" for="dataFim">Fim</label>
                        <div class="d-flex align-items-center"> <!-- Adicione uma classe flex para alinhar os elementos verticalmente -->
                            <input matInput [matDatepicker]="picker2" [formControlName]="'dataFim'" id="dataFim" class="form-control" placeholder="DD/MM/YYYY"/>
                            <mat-datepicker-toggle matIconSuffix [for]="picker2" class="mat-datepicker-start"></mat-datepicker-toggle> <!-- Adicione a classe mat-datepicker-start aqui -->
                            <mat-datepicker #picker2></mat-datepicker>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="isAdmin">
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Tipo </label>
                        <input type="text" placeholder="Selecione um" aria-label="Tipo de requerimento" matInput
                            [formControl]="myControl" [matAutocomplete]="auto"
                            [formControlName]="'tipoRequerimento'" class="form-control"  (input)="onChange($event)" />

                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                  <ng-container *ngIf="option.tipoRequerimentoPai">
                                    {{ option.tipoRequerimentoPai.nome }} - {{ option.nome }}
                                  </ng-container>
                                  <ng-container *ngIf="!option.tipoRequerimentoPai">
                                    {{ option.nome }}
                                  </ng-container>
                                </mat-option>
                              </mat-autocomplete>
                              
                               
                    </div>
                    <div class="col-md-8">

                    </div>

                    
                    

                   

                    <div class="col-12">
                        <button class="btn btn-primary me-3" type="submit">Buscar</button>
                        <button class="btn btn-danger" type="reset" (click)="limpar()">Limpar</button>

                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <label for="quantidade" class="form-label"></label>
            <select class="form-select" id="quantidade" formControlName="quantidade"
                (change)="alterarQuantidade($event)" required>
                <option disabled selected value="">Quantidade</option>
                <option value="1">1</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="100">100</option>
                <option value="200">200</option>
            </select>
            <div class="invalid-feedback">
                Please select a valid state.
            </div>
        </div>
    </form>
</div>
<div class="container text-center" id="listagemRequerimentos">

    <div class="card bg-transparent border-0 row row-cols-auto g-0 text-center row-requerimentos-header">
        <!-- <div class="col-md-1 h-100 vertical-center">
            Requerimento
        </div> -->

        <div class="col-md-2 h-100 vertical-center" *ngIf="isAdmin">
            Nome
        </div>
        <div class="col-md-2 h-100 vertical-center" *ngIf="isAdmin">
            CPF
        </div>
        <div class="col-md-1 h-100 vertical-center no-mobile">
            Status
        </div>
        <div class="col-md-2 h-100 vertical-center no-mobile">
            Tipo
        </div>
        <div class="col-md-1 h-100 vertical-center no-mobile">
            N° Protocolo
        </div>
        <div class="col-md-2 h-100 vertical-center no-mobile">
            N° SEI
        </div>
        <div class="col-md-1 h-100 vertical-center no-mobile">
            Data Abertura
        </div>
        <div class="col-md-1 h-100 vertical-center ">
            Ações
        </div>
    </div>
    <ng-container *ngIf="!carregando">
        <div class="accordion requerimentos accordion-flush" id="accordionFlushExample">
            <ng-container *ngFor="let requerimento of requerimentos">
                
                <div class="card mb-2 shadow">
                    <div
                        class="accordion-item-- flex-column row row-cols-auto text-center g-0 row-requerimentos p-0 my-2">
                        <!--  <div class="col-md-1 h-100 vertical-center requerimento-id">
                            {{ requerimento.id }}
                        </div> -->
                        <div class="col-md-2 h-100 vertical-center" *ngIf="isAdmin">
                            {{ requerimento.nomeRequerente }}
                        </div> 
                        <div class="col-md-2 h-100 vertical-center" *ngIf="isAdmin">
                            {{ requerimento.cpfRequisitado | slice:0:3 }}.{{ requerimento.cpfRequisitado | slice:3:6 }}.{{ requerimento.cpfRequisitado | slice:6:9 }}-{{ requerimento.cpfRequisitado | slice:9 }} &nbsp;
                            <i class="bi bi-clipboard" (click)="copiarMensagm( requerimento.cpfRequisitado )" matTooltip="Copiar cpf"></i>

                        </div>
                        <div class="col-md-1 h-100 vertical-center no-mobile">
                            <span class="badge" [ngClass]="{
                            'text-bg-success': requerimento.status.id === 1,
                            'text-bg-primary': requerimento.status.id === 2,
                            'text-bg-info': requerimento.status.id === 3,
                            'text-bg-secondary': requerimento.status.id === 4,
                            'text-bg-danger': requerimento.status.id === 5
                            }
                            ">{{ requerimento.status.descricao }}</span>
                        </div>
                        <div class="col-md-2 h-100 overflow-hidden vertical-center no-mobile">
                            {{ requerimento.tipoRequerimento.tipoRequerimentoPai?.nome ? requerimento.tipoRequerimento.tipoRequerimentoPai.nome + ' / ' : '' }}{{ requerimento.tipoRequerimento?.nome }}
                        </div>
                        <div class="col-md-1 h-100 vertical-center no-mobile">
                            {{ requerimento.numeroProtocolo }}
                        </div>
                        <div class="col-md-2 h-100 vertical-center no-mobile">
                            {{ requerimento.numeroSEI }}
                        </div>
                        <div class="col-md-1 h-100 vertical-center no-mobile">
                            {{ requerimento.dataAbertura | date: 'dd/MM/yyyy' }}
                        </div>
                        <div class="col-md-1 h-100 vertical-center float-end" id="flush-headingOne-{{requerimento.id}}">

                            <ng-container *ngIf="requerimento.status.id === 1 && isAdmin" >
                                <button type="button" class="btn btn-action-requerimentos p-0 no-mobile"
                                    (click)="validarRequerimento(requerimento.id, requerimento)" ><i
                                        class="bi bi-check2-circle text-success" matTooltip="Validar"></i></button>
                            </ng-container>

                            <ng-container *ngIf="requerimento.status.id === 1  && isAdmin">
                                <button type="button" class="btn btn-action-requerimentos p-0 no-mobile"
                                    (click)="editarRequerimento(requerimento.id, requerimento)"><i
                                        class="bi bi-check2-circle bi-pencil" matTooltip="Editar número do SEI"></i></button>
                            </ng-container>

                            <ng-container *ngIf="requerimento.status.id === 2  && isAdmin">
                                <button type="button" class="btn btn-action-requerimentos p-0 no-mobile"
                                        (click)="baixarZip(requerimento.id, requerimento.numeroProtocolo)"
                                        ><i
                                        class="bi bi-download text-success" matTooltip="Baixar arquivo"></i></button>   
                                <button type="button" class="btn btn-action-requerimentos p-0 no-mobile" 
                                    (click)="editarNumeroSEI(requerimento.id, requerimento.numeroSEI)"><i
                                        class="bi bi-pencil text-success" matTooltip="Incluir/Editar Nº SEI"></i></button>                                                                        
                            </ng-container>
                           
                            <ng-container *ngIf="requerimento.status.id === 3 && isAdmin">
                                <button type="button" class="btn btn-action-requerimentos p-0 no-mobile"
                                    (click)="finalizarRequerimento(requerimento.id, requerimento)"><i
                                        class="bi bi-clipboard2-x-fill text-danger" matTooltip="Finalizar"></i></button>
                            </ng-container>
                            <button type="button" class="btn btn-action-requerimentos p-0 no-mobile"
                                (click)="cancelarRequerimento(requerimento.id, requerimento)" *ngIf="[ 1, 2].includes(requerimento.status.id) && isAdmin"><i
                                    class="bi bi-x-circle text-danger" matTooltip="Cancelar"></i></button>
                           
                            <a class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"  aria-current="page" [routerLink]="'/requerimento/detalhes/' + requerimento.id"><i class="bi bi-file-earmark-text" matTooltip="Visualizar"></i></a>
                        </div>
                    </div>
                    <div id="flush-collapseOne-{{ requerimento.id }}" class="card-body-- accordion-collapse collapse"
                        attr.aria-labelledby="flush-headingOne-{{requerimento.id}}"
                        data-bs-parent="#accordionFlushExample">
                        Detalhes

                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="paginacaoResponse.registros.length == 0">
                <div class="alert alert-danger" role="alert">
                 Nenhum registro encontrado.
                 </div>
            </ng-container>
        </div>

        <div *ngIf="!carregando" class="row mt-5">
            
            <nav *ngIf="paginacaoResponse && paginacaoResponse.registros.length > 0">
                <ul class="pagination justify-content-center">
                    <li class="page-item" *ngIf="paginaAtual>1">
                        <a class="page-link" href="javascript:void(0);" (click)="voltarParaAnterior(1)">Primeira</a>
                      </li>
                  <li class="page-item" *ngIf="paginaAtual>1">
                    <a class="page-link" href="javascript:void(0);" (click)="voltarParaAnterior(paginaAtual)">Anterior</a>
                  </li>
                  <li class="page-item" *ngIf="deveExibirReticenciasInicio()">
                    <span class="page-link">...</span>
                  </li>
                  <li *ngFor="let pagina of getPaginasVisiveis()" class="page-item">
                    <a class="page-link" [ngClass]="{'active': pagina === paginaAtual}"
                      (click)="irParaPagina(pagina)" href="javascript:void(0);"> {{ pagina }}</a>
                  </li>
                  <li class="page-item" *ngIf="deveExibirReticenciasFim()">
                    <span class="page-link">...</span>
                  </li>
                  <li class="page-item" *ngIf="paginaAtual!=paginas.length">
                    <a class="page-link" href="javascript:void(0);" (click)="irParaProxima(paginaAtual)">Próxima</a>
                </li>
                <li class="page-item" *ngIf="paginaAtual!=paginas.length">
                  <a class="page-link" href="javascript:void(0);" (click)="irParaPagina(paginas.length)">Última</a>
                </li>
                  
                </ul>
              </nav>
              
              
        </div>
    </ng-container>
</div>

<div *ngIf="carregando" class="container text-center mt-5">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>



<!-- <div class="accordion requerimentos accordion-flush" id="accordionFlushExample">

    <div class="card mb-2 p-2">
        <div class="accordion-item ">
            <div class="accordion-header d-flex flex-row" id="flush-headingOne-2">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne-2" aria-expanded="false" aria-controls="flush-collapseOne">
                    #1
                </button>
                <div class="flex-column flex-grow-1 h-100">
                    <div class="">Tipo</div>
                    <div class="">Flex item</div>
                </div>
                <div class="p-2 flex-shrink-1">
                    <button class="accordion-button collapsed p-1" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne-2" aria-expanded="false" aria-controls="flush-collapseOne">
                    </button>
                </div>
            </div>
            <div id="flush-collapseOne-2" class="accordion-collapse collapse" aria-labelledby="flush-headingOne-2"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the
                    <code>.accordion-flush</code> class. This is the first item's accordion body.
                </div>
            </div>
        </div>
        <div class="accordion-item ">
            <div class="accordion-header d-flex flex-row" id="flush-headingOne-3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne-3" aria-expanded="false" aria-controls="flush-collapseOne">
                    #1
                </button>
                <div class="flex-column flex-grow-1 h-100">
                    <div class="">Tipo</div>
                    <div class="">Flex item</div>
                </div>
                <div class="p-2 flex-shrink-1">
                    <button class="accordion-button collapsed p-1" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne-3" aria-expanded="false" aria-controls="flush-collapseOne">
                    </button>
                </div>
            </div>
            <div id="flush-collapseOne-3" class="accordion-collapse collapse" aria-labelledby="flush-headingOne-3"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the
                    <code>.accordion-flush</code> class. This is the first item's accordion body.
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-2 p-2">
        <div class="accordion-item">
            <span class="accordion-header" id="flush-headingOne-3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne-3" aria-expanded="false" aria-controls="flush-collapseOne">
                    Accordion Item #1
                </button>
            </span>
            <div id="flush-collapseOne-3" class="accordion-collapse collapse" aria-labelledby="flush-headingOne-3"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the
                    <code>.accordion-flush</code> class. This is the first item's accordion body.
                </div>
            </div>
        </div>
    </div>
</div> -->