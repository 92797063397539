import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AcessoExterno } from 'src/app/core/models/domain/AcessoExterno';
import { Requerimento } from 'src/app/core/models/domain/requerimento-class.model';
import { EmailMessageDTO } from 'src/app/core/models/email/email-message-dto';
import { EmailService } from 'src/app/core/services/email.service';
import { RequerimentoService } from 'src/app/core/services/requerimento.service';
import { TokenService } from 'src/app/core/services/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reenvio-token',
  templateUrl: './reenvio-token.component.html',
  styleUrls: ['./reenvio-token.component.css']
})
export class ReenvioTokenComponent {

  msgSuccess: string = '';
  msgFailure: string = '';
  msgFailureNumeroProtocolo: string = '';

  requerimento: Requerimento = null;

  numeroProtocoloValido = false;

  readonly nameSpinner: string = 'tokenSpinner';

  textoTokenSpinner: string = '';

  constructor(private tokenService: TokenService, private emailService: EmailService, private requerimentoService: RequerimentoService, private ngxSpinnerService: NgxSpinnerService) {

  }

  reenvioForm = new FormGroup({
    numeroProtocolo: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    cpf: new FormControl('', [Validators.required]),
  });

  get form(): any {
    return this.reenvioForm.controls;
  }

  onSubmit(): void {

    this.textoTokenSpinner = "Gerando o acesso do usuário."
    this.ngxSpinnerService.show(this.nameSpinner);

    let acessoExterno: AcessoExterno = {
      emailRequerente: this.reenvioForm.value.email,
      idRequerimento: this.requerimento,
      dataExpiracao: undefined,
      token: ''
    }

    this.tokenService.gerarAcessoExterno(acessoExterno)
      .subscribe({
        next: (response) => {
          console.log(response);

          let emailMessageDTO: EmailMessageDTO = {
            to: this.reenvioForm.value.email as string,
            identifier: this.reenvioForm.value.numeroProtocolo,
            subject: 'Acesso ao Requerimento Nº ' + this.reenvioForm.value.numeroProtocolo,
            content: '',
            template: 'token',
            token: response.token,
            domainApp: environment.domainApp
          };

          this.emailService.sendEmail(emailMessageDTO).subscribe({
            next: (response) => {

              this.msgFailure = '';

              this.msgSuccess = response.message;
              this.ngxSpinnerService.hide(this.nameSpinner);

            },
            error: (e) => {

              this.msgFailure = 'Erro ao gerar o acesso do usuário.';
              this.ngxSpinnerService.hide(this.nameSpinner);

            }
          });
        }

        ,
        error: (e) => {
          this.msgFailure = 'Erro ao gerar o acesso para o usuário.';

          this.ngxSpinnerService.hide(this.nameSpinner);
        }
      });

  }

  validarNumeroProtocolo(event: any): void {

    if (event.target.value?.length >= 7) {

      this.textoTokenSpinner = 'Validando o Nº Protocolo.';
      this.ngxSpinnerService.show(this.nameSpinner);

      this.requerimentoService.findByNumeroProtocolo(this.reenvioForm.controls.numeroProtocolo?.value).subscribe({
        next: (response) => {
          console.log(response);

          if (response == null || response.length == 0) {
            this.numeroProtocoloValido = false;
            this.reenvioForm.controls.numeroProtocolo.setErrors({ errorNumeroProtocolo: true });
            this.msgFailureNumeroProtocolo = 'Não foi encontrado nenhum Requerimento com o protocolo informado.';
          } else {
            this.numeroProtocoloValido = true;
            this.requerimento = response[0];
          }

          this.ngxSpinnerService.hide(this.nameSpinner);
        },
        error: (e) => {
          this.reenvioForm.controls.numeroProtocolo.setErrors({ errorNumeroProtocolo: true });
          this.msgFailureNumeroProtocolo = 'Erro ao verificar o número do protocolo.';

          this.numeroProtocoloValido = false;

          this.ngxSpinnerService.hide(this.nameSpinner);
        }
      });

    }
  }

}
