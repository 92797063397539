<div class="card container px-4 py-5 bg-white shadow">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
        <div class="col-10 col-sm-8 col-lg-6">
            <img src="./assets/imagens/acesso_negado.png" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes"
                width="310" loading="lazy">
        </div>
        <div class="col-lg-6 text-center">
            <span class="display-6 fw-bold lh-1 mb-3">Esse link esta desatualizado</span>
            <p class="lead my-3 text-center">Favor entrar em contato com a SGP para solicitar um novo token de acesso ao
                requerimento por e-mail.</p>
            <p class="lead my-3 text-center">Para retornar para a página de Início clique no botão abaixo.</p>
            <button mat-flat-button color="primary" [routerLink]="['/']">Home</button>
        </div>
    </div>
</div>