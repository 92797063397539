<div class="container login">
  <div class="row justify-content-center h-100 mx-auto">
    <div class="col-xxl-4 col-xl-5 col-lg-6 col-md-8 my-auto">
      <div class="login-wrap p-4 p-md-5">
        <div class="icon- d-flex align-items-center justify-content-center mb-4">
          <!-- <span class="fa fa-user-o"></span> -->
          <img class="img-responsive" width="110" src="./assets/imagens/logomarca-uerj.png">
        </div>
        <h3 class="text-center">SISTEMA DE REQUERIMENTOS</h3>
        <div class="small text-center p-2">Infome suas credenciais do id único para acesso ao sistema SISRE.</div>
        <form id="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
          <div class="form-group mb-2">
            <!-- <input type="text" class="form-control rounded-left" placeholder="CPF" required> -->
            <mat-form-field class="form-control rounded-left">
              <mat-label>CPF</mat-label>
              <input placeholder="" matInput formControlName="username" type="text" mask="000.000.000-00" minlength="11"
                (input)="mensagemErro = ''" required />
              <!--<div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">CPF é obrigatório</div>
              </div>-->

              <mat-error *ngIf="f.username.errors && f.username.errors.required">O campo CPF é obrigatório.</mat-error>
              <mat-error *ngIf="f.username.errors && f.username.errors.minlength">O campo CPF deve conter 11
                dígitos.</mat-error>

            </mat-form-field>
          </div>
          <div class="form-group mb-2 ng-invalid">
            <mat-form-field class="form-control rounded-left">
              <!-- <input type="password" class="form-control rounded-left" placeholder="Password" required> -->
              <mat-label>Senha</mat-label>
              <input placeholder="" matInput type="password" formControlName="password" minlength="10"
                (input)="mensagemErro = ''" required />
              <!--[ngClass]="{ 'is-invalid': submitted && f.password.errors }"-->
              <!--<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Senha é obrigatória</div>
              </div>-->
              <mat-error *ngIf="f.password.errors && f.password.errors.required">Senha é obrigatória.</mat-error>

            </mat-form-field>
          </div>
          <div class="form-group d-md-flex my-4">
            <div class="col-md-6 col-auto text-center">
              <a href="https://www.id-unico.uerj.br/view/paginas/primeiroAcesso.jsf?active=primeiroAcesso"
                target="_blank">Realizar
                Cadastro</a>
            </div>
            <div class="col-md-6 col-auto text-center">
              <a href="https://www.id-unico.uerj.br/view/paginas/esqueceuSenhaUsuario.jsf" target="_blank">Recuperar
                Senha</a>
            </div>
          </div>
          <mat-error *ngIf="mensagemErro" [hidden]="" class="error-login">{{ mensagemErro }}</mat-error>
        </form>
        <div>
          <button type="submit" class="btn btn-primary rounded submit p-3 w-100" form="loginForm" type="submit"
            [disabled]="!loginForm.valid">Entrar</button>
        </div>
      </div>
    </div>
  </div>
</div>