<mat-card style="padding: 20px;">
    <mat-card-head class="card-header">
        <mat-card-title>
            <div class="divhr">
                <p class="text-center corTituloSobBarra">
                    Abertura de Requerimento
                </p>
            </div>
        </mat-card-title>
    </mat-card-head>
    <mat-card-content class="text-center">
        <div class="d-flex justify-content-center">
            <div class="alert alert-success w-75 p-3" role="alert">
                <h4 class="alert-heading">Requerimento Aberto com Sucesso!</h4>
                <p>O Nº Protocolo é <strong># {{ numeroProtocolo }} </strong> em breve você receberá um e-mail
                    com
                    a
                    confirmação da abertura deste requerimento.</p>
            </div>
        </div>
        <mat-divider></mat-divider>
    </mat-card-content>
    <mat-card-footer>
        <div class="parent">
            <mat-card-actions>
                <div class="text-center">
                    <button mat-raised-button routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                        [routerLink]="'/'" color="secondary">Voltar</button>
                    <button style="margin-left: 5px;" mat-button color="default" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}" aria-current="page"
                        [routerLink]="['/abertura', { outlets: { 'abertura': ['inicio'] } } ]">Novo
                        Requerimento</button>
                </div>
            </mat-card-actions>
        </div>
    </mat-card-footer>
</mat-card>